<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="conceptModal"
    >
      <CRow>
        <CCol md="12">
          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('basic')" class="nav-item":class="tab == 'pending' ? 'active' : ''" v-if="!isEdit">
              <a class="nav-link" :class="tab == 'basic' ? 'active' : ''">Básica</a>
            </li>
            <li @click="changeTab('manual')" class="nav-item">
              <a class="nav-link" :class="tab == 'manual' ? 'active' : ''">Manual</a>
            </li>
          </ul>    
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12">
          <CForm>
            <CRow v-if="tab == 'basic'">
              <CCol md="3">
                <CInput
                  label="Cantidad:"
                  :lazy="false"
                  :isValid="checkIfValid('quantity')"
                  :value.sync="$v.form.quantity.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                  @change="updateImport"
                />
              </CCol>
              <CCol md="9">
                <CSelect
                  label="Producto:"
                  :isValid="checkIfValid('concept')"
                  :value.sync="$v.form.concept.$model"
                  :options="productsOptions"
                  @change="changeProduct"
                />
              </CCol>
            </CRow>
            <CRow v-if="tab == 'manual'">
              <CCol md="3">
                <CInput
                  label="Cantidad:"
                  :lazy="false"
                  :isValid="checkIfValid('quantity')"
                  :value.sync="$v.form.quantity.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                  @change="updateImport"
                />
              </CCol>
              <CCol md="3">
                <CSelect
                  label="Unidad:"
                  :isValid="checkIfValid('unit_code')"
                  :value.sync="$v.form.unit_code.$model"
                  :options="unitsOptions"
                  @change="changeUnit"
                />
              </CCol>
              <CCol md="6" >
                <CSelect
                  label="Código SAT:"
                  :isValid="checkIfValid('fiscal_product')"
                  :value.sync="$v.form.fiscal_product.$model"
                  :options="fiscalProductsOptions"
                />
              </CCol>
            </CRow>
            <CRow v-if="tab == 'manual'">
              <CCol md="12">
                <CTextarea
                  label="Concepto:"
                  name="concept"
                  :lazy="false"
                  :isValid="checkIfValid('concept')"
                  :value.sync="$v.form.concept.$model"
                  placeholder=""
                  rows="5"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="3">
                <CInput
                  label="Precio Unitario:"
                  :lazy="false"
                  :isValid="checkIfValid('price')"
                  :value.sync="$v.form.price.$model"
                  placeholder=""
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                  @change="updateImport"
                />
              </CCol>
              <CCol md="2">
                <CInput
                  label="Descuento:"
                  :lazy="false"
                  :value.sync="$v.form.discount.$model"
                  placeholder=""
                  @change="updateImport"
                />
              </CCol>
              <CCol md="4" >
                <CSelect
                  label="Impuestos:"
                  :isValid="checkIfValid('taxes')"
                  :value.sync="$v.form.taxes.$model" 
                  :options="taxesOptions"
                />
              </CCol>
              <CCol md="3">
                <CInput
                  label="Importe:"
                  :lazy="false"
                  :isValid="checkIfValid('import')"
                  :value.sync="$v.form.import.$model"
                  placeholder=""
                  :readonly="true"
                  invalidFeedback="Este campo es requerido y debe ser valor mayor que cero."
                />
              </CCol>
            </CRow>
          </CForm>
        </CCol>
      </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, minValue, email} from "vuelidate/lib/validators"
import { DatePicker } from 'v-calendar'
import serials_ws from '../../../services/serials';
import units_ws from '../../../services/units';
import fiscal_products_ws from '../../../services/fiscal-products';
import general_ws from '../../../services/general';
import products_ws from '../../../services/products';
import taxes_ws from '../../../services/taxes';

import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'ConceptModal',
  props: {

  },
  components: {
    'v-date-picker': DatePicker
  },
  data () {
    return {
      conceptModal: false,
      title: "Agregar Concepto", 
      color: "info",
      isEdit: false,
      tab: "basic",
      form: {},
      submitted: false,
      products: [],
      productsOptions: [],
      units: [],
      unitsOptions: [],
      fiscal_products: [],
      fiscalProductsOptions: [],
      taxes: [],
      taxesOptions: [],
      default_unit: false,
      default_tax: false
    }
  },
  mounted: async function() {
    let response1 = await products_ws.get(); 

    if(response1.type == "success"){
      this.products = response1.data;

      this.productsOptions = await this.$parseSelectOptionsOrdered(this.products, "id", "name");
    }

    let response2 = await units_ws.get(); 

    if(response2.type == "success"){
      this.units = response2.data;

      this.default_unit = await this.$findDefaultUnit(this.units);
    }

    this.unitsOptions = await this.$parseSelectOptionsOrdered(this.units, "code", "code:name");

    let response3 = await fiscal_products_ws.get(); 

    if(response3.type == "success"){
      this.fiscal_products = response3.data;
    }

    this.fiscalProductsOptions = await this.$parseSelectOptionsOrdered(this.fiscal_products, "sat_code", "sat_code:name");

    let response4 = await taxes_ws.list(); 

    if(response4.type == "success"){
      this.taxes = response4.data;

      this.default_tax = await this.$findDefaultTax(this.taxes);
    }

    this.taxesOptions = await this.$parseSelectOptionsOrdered(this.taxes, "code", "name");
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab && !this.isEdit){
        this.tab = tab;

        this.form.tab = this.tab;

        if(this.tab == "basic"){
          this.form.concept = this.products[0].id;
        }
        else{
          this.form.concept = "";
        }

        this.submitted = false
        this.$v.$reset()
      }
    },
    async changeProduct () {
      let product = await this.$findElementById(this.products, this.form.concept);
      this.form.price = product.price > 0 ? this.$options.filters.decimal(product.price) : '';
      this.form.import = this.$options.filters.currency(this.form.quantity * this.form.price);
    },
    async updateImport () {
      if(this.form.quantity > 0 && this.form.price > 0){
        this.form.import = this.$options.filters.currency(this.form.quantity * this.form.price);
      }
    },
    async changeUnit () {
      let unit = await this.$findElementByCode(this.units, this.form.unit_code);
      this.form.unit_name = unit.name;
    },
    addConceptModal (data) {
      this.conceptModal = true;
      this.color = "info";
      this.title = 'Agregar Concepto';
      this.isEdit = false;
      this.submitted = false;

      let concept = "";
      let price = 0.00;
      let amount = 0.00;

      if(this.tab == "basic" && this.products[0] !== undefined){
        concept = this.products[0].id;
        price = this.products[0] !== undefined ? this.$options.filters.decimal(this.products[0].price) : 100.00;
        amount = this.products[0] !== undefined ? this.$options.filters.decimal(this.products[0].price) : 100.00;
      }

      this.form = {
        quantity: '1.00',
        concept: concept,
        unit_code: this.default_unit.code,
        unit_name: this.default_unit.name,
        fiscal_product: "01010101",
        taxes: this.default_tax.code,
        price: price,
        discount: '0.00',
        import: amount,
        tab: this.tab
      };
    },
    editConceptModal (data) {
      this.conceptModal = true;
      this.color = "info";
      this.title = 'Editar Concepto';
      this.isEdit = true;
      this.submitted = false;

      this.tab = "manual";
      this.form.tab = "manual";

      this.form = {
        quantity: data.quantity+"",
        concept: data.description,
        unit_code: data.unit.unit_code,
        unit_name: data.unit.unit_name,
        fiscal_product: data.sat_code,
        taxes: data.tax_id,
        price: data.price+"",
        discount: data.discount+"",
        import: data.total+"",
      };
    },
    async store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        let taxes = await this.$findTax(this.taxes, this.form.taxes);
        
        if(!this.isEdit){
          this.$emit("addConcept", this.tab, this.form, taxes);
        }
        else{
          this.$emit("updateConcept", this.tab, this.form, taxes);
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.conceptModal = false;

      this.submitted = false
      this.$v.$reset()
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid)
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      quantity: {
        required, 
        minValue: minValue(0.01)
      },
      concept: {
        required
      },
      price: {
        required, 
        minValue: minValue(0.01)
      },
      discount: false,
      import: false,
      unit_code: {},
      unit_name: false,
      fiscal_product: {},
      taxes: {
        required
      },
    }
  },
}
</script>